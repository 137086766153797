import React from 'react';
import styled from 'styled-components';

const ButtonCrossStyled = styled.button`
  border: none;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 1.0875rem;
  top: 1.0875rem;
  z-index: 1000;

  span {
    background: black;
    position: absolute;
    height: 1px;
    top: 50%;
    left: 0;
    bottom: 50%;
    right: 0;
    pointer-events: none;
    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`

const ButtonCross = ({ onClick }) => {

  return(
    <ButtonCrossStyled
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
      type="button"
    >
      <span />
      <span />
    </ButtonCrossStyled>
  )
}

export default ButtonCross;
