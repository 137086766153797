/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import Header from "./header"
import theme from "../styles/theme"
import GlobalStyle from "../styles/global"
import Footer from "./footer"
import MentionsLegales from "./mentionsLegales"

const App = styled.div`
  flex-shrink: 1;
  overflow: auto;
  width: 100%;
  min-width: 1050px;
  height: 100vh;
`

const Layout = ({ children }) => {
  const [showMentionsLegales, toggleMentionsLegales] = React.useState(false)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme} />
      <App>
        <Header />
        {children}
        <Footer onMentionsLegalesClick={() => toggleMentionsLegales(true)} />
      </App>
      {showMentionsLegales && (
        <MentionsLegales onCloseClick={() => toggleMentionsLegales(false)} />
      )}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
