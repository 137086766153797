
export const CABINET = 'cabinet';
export const COMPETENCES = 'compétences';
// export const ACTUALITES = 'actualités';
export const CONTACT = 'contact';
export const COORDONNEES = 'coordonnées';
export const HONORAIRES = 'honoraires';

export const anchors = [
  CABINET,
  COMPETENCES,
  HONORAIRES,
  COORDONNEES,
]
