
import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeContext } from 'styled-components'
import Nav from "./nav"
import Logo from "./logo"
import { displayFlex } from "../styles/mixins"
import { media } from "../styles/mediaQueries"

const FooterStyled = styled.footer`
  width: 100%;
  max-width: 100vw;
  background-color: ${props => props.theme.color.black};
  ${displayFlex({
    justifyContent: 'space-around',
    flexDirection: 'column',
  })}
  height: ${props => props.theme.height.footer}px;
  ${media.desktop`
    height: ${props => props.theme.height.desktop.footer}px;
    flex-direction: row;
  `}
`

const Footer = ({ siteTitle, onMentionsLegalesClick }) => {

  const { color: { backgroundColor } } = React.useContext(ThemeContext);

  return(
    <FooterStyled id='contact'>
      <Logo color={backgroundColor} />
      <Nav color={backgroundColor} footer onMentionsLegalesClick={onMentionsLegalesClick} />
    </FooterStyled>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
