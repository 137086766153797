import React from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { displayFlex } from '../styles/mixins';
import { anchors } from '../reference/anchors';
import { formatString } from '../helpers/selectors';

const NavStyled = styled.nav`
  ${props => !props.footer && 'flex-grow: 1;'}
  ${displayFlex({
    justifyContent: 'space-around'
  })}
  max-width: 700px;
`

const NavButton = styled(AnchorLink)`
  color: ${props => props.color};
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`

const Nav = ({ footer, onMentionsLegalesClick, color }) => {

  return(
    <NavStyled footer={footer}>
      {footer
      ? (
        <NavButton
          as='button'
          type='button'
          color={color}
          onClick={onMentionsLegalesClick}
        >
          Mentions légales
        </NavButton>
      )
      : anchors.map(anchor => (
        <NavButton
          key={anchor}
          color={color}
          href={'#' + formatString(anchor)}
          onClick={e => {
            e.preventDefault();
            document.getElementById(formatString(anchor)).scrollIntoView({ behavior: 'smooth' })
          }}
        >
          {anchor}
        </NavButton>
      ))}
    </NavStyled>
  )
}

export default Nav;
