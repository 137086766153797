import React from "react"
import Modale from "./modale"

const MentionsLegales = ({ onCloseClick }) => {
  const [email, setEmail] = React.useState("")
  const [tel, setTel] = React.useState("")

  React.useEffect(() => {
    setEmail(window.atob("Y29udGFjdEBkLW0tYXZvY2F0LmNvbQ"))
    setTel(window.atob("OSA3MiA2NiA0MiAzMQ=="))
  }, [setEmail])

  return (
    <Modale fullScreen onCloseClick={onCloseClick}>
      <p>
        INFORMATIONS LÉGALES || Diallo-Missoffe AVOCAT
        <br />
        <br />
        Le site www.diallo-missoffe-avocat.com est édité par l’association
        d’avocats à responsabilité professionnelle individuelle Diallo-Missoffe
        Avocat dont le siège social est situé:
        <br />
        <br />
        21 rue de Carency 93000 BOBIGNY
        <br />
        <br />
        Téléphone +33 {tel}
        Adresse de courrier électronique : {email}
        Le Directeur de la publication est Audrey Diallo-Missoffe.
        <br />
        <br />
        Hébergement
        <br />
        <br />
        OVH 2 rue Kellermann 59100 Roubaix France
        <br />
        <br />
        Propriété intellectuelle
        <br />
        <br />
        L'internaute s'engage à respecter le droit de propriété intellectuelle
        du Site ainsi que son contenu, quelle qu'en soit la nature. En
        particulier, l'internaute s'interdit toute reproduction ou toute
        représentation du Site, par quelque moyen et sous quelque forme que ce
        soit, sans l'autorisation expresse et préalable de Diallo-Missoffe
        Avocat. Les bases de données figurant sur le site sont protégées par les
        articles L.341-1 et suivants du Code de la propriété intellectuelle et
        est sanctionnée toute extraction ou réutilisation qualitativement ou
        quantitativement substantielle du contenu des bases de données.
        <br />
        <br />
        Liens
        <br />
        <br />
        Les liens proposés vers des sites tiers ne sauraient engager la
        responsabilité de Diallo-Missoffe Avocat quant à leurs conditions
        d’accès et à leur contenu.
        <br />
        <br />
        Conformément aux dispositions des articles L. 612-1 et suivants du Code
        de la consommation, vous avez la possibilité, en cas de litige avec un
        avocat, de recourir gratuitement au Médiateur de la consommation qui
        sera le médiateur national près du Conseil National des Barreaux (CNB)
        et dont les coordonnées sont les suivantes : CNB, Médiateur à la
        consommation, 22 rue de Londres, 75009 Paris.
        <br />
        <br />
        Remerciements :
        <br />
        <br />
        Arnaud Ambroselli
        <br />
        <br />
        Photos :
        <br />
        <br />
        Photo by Alex Vasey on Unsplash
        <br />
        <br />
      </p>
    </Modale>
  )
}

export default MentionsLegales
