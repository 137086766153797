import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { defaultColor } from '../styles/theme';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { CABINET } from '../reference/anchors';
import { formatString } from '../helpers/selectors';
import { media } from '../styles/mediaQueries';

const titleLogoCss = css`
  font-size: 1.5em;
  line-height: 2.5em;
  ${media.desktop`
    font-size: 3.5em;
    line-height: 1em;
  `}
`

const otherLogoCss = css`
  line-height: 1em;
  font-size: 1.5em;
`;

const LogoStyled = styled.a`
  font-family: "Zapfino", sans-serif;
  color: ${props => props.color};
  padding-top: 0.5em;
  margin: 0 1em;
  ${props => props.forTitle && titleLogoCss}
  ${props => !props.forTitle && otherLogoCss}
  ${props => props.forIe && `
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-top: 1em;
      }
  `}
`

const Logo = ({ color, forTitle, short, forIe }) => {

  const { title, shortTitle } = useSiteMetadata()

  return(
    <LogoStyled
      color={color}
      forTitle={forTitle}
      forIe={forIe}
      href={'#' + formatString(CABINET)}
      onClick={e => {
        e.preventDefault();
        document.getElementById(formatString(CABINET)).scrollIntoView({ behavior: 'smooth' })
      }}
    >
      {short ? shortTitle : title}
    </LogoStyled>
  )
}

Logo.propTypes = {
  color: PropTypes.string,
  short: PropTypes.bool,
}

Logo.defaultProps = {
  color: defaultColor,
  short: false,
}

export default Logo;
