export const black = 'rgba(19,21,22,1)'
export const white = 'rgba(252,252,252,1)'
export const grey = 'rgba(235,234,239,1)'
export const darkGrey = 'rgba(200,200,200,1)'
export const green = 'rgba(49, 183, 147, 1.00)';
export const primary = 'rgba(133, 134, 139, 1.00)';
// export const primary = 'rgba(129, 47, 123, 1.00)';
export const backgroundColor = white;
export const defaultColor = black;
const boxShadow = '0px 0px 26px 0px rgba(220,220,220,0.5)'

export const setRgbaOpacity = (rgba, opacity) => rgba.replace(/,.[0-1]\.?[0-9]*\)/, `,${opacity})`)

const theme = {
  color: {
    black,
    white,
    primary,
    grey,
    darkGrey,
    defaultColor,
    backgroundColor,
    defaultColorFaded: setRgbaOpacity(defaultColor, 0.17),
    secondaryBg: 'rgba(249, 249, 249, 1.00)',
  },
  boxShadow: {
    default: boxShadow
  },
  zIndex: {
  },
  borderRadius: {
  },
  height: {
    header: 70,
    footer: 250,
    cabinet: 550,
    section: 50,
    desktop: {
      footer: 170,
    }
  },
  width: {
    oblique: 100,
    min: {
    },
    max: {
    },
  },
  margin: {
    top: {
    },
    bottom: {
      section: 10,
    },
    right: {
    },
    left: {
    },
    X: {
    },
  },
  padding: {
    X: {
    },
    Y: {
    }
  },
  duration: {}
}

export default theme
