
/* GATSBY-IMAGE */
const getImagePropsForGatsby = edges => edges.node.fluid;
const getImageSrcFromImages = edges => getImagePropsForGatsby(edges).src;

/* GET IMAGE FLUID */
const getImageFromSrc = (images, src) => getImagePropsForGatsby(
  images
  .find(img => getImageSrcFromImages(img).includes(src))
  )


const formatString = string =>
  (string && typeof string === 'string' && string.length > 0)
  ? string.replace(/[\u200B-\u200D\uFEFF\u202c]/g, '')
  : string;


export {
  getImagePropsForGatsby,
  getImageSrcFromImages,
  getImageFromSrc,
  formatString,
}
