
import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeContext } from 'styled-components'
import Nav from "./nav"
import Logo from "./logo"
import { displayFlex } from "../styles/mixins"
import { media } from "../styles/mediaQueries"

const HeaderStyled = styled.header`
  display: none;
  ${media.desktop`
    ${displayFlex({
      justifyContent: 'space-around'
    })}
  `}
  width: 100%;
  height: ${props => props.theme.height.header}px;
  background-color: ${props => props.theme.color.backgroundColor};
  flex-shrink: 0;
  z-index: 1000;
  position: fixed;
  position: sticky;
  top: 0;
  border-bottom: 2px solid ${props => props.theme.color.primary};
`

const Header = ({ siteTitle, onMentionsLegalesClick }) => {

  const { color: { primary } } = React.useContext(ThemeContext);

  return(
    <HeaderStyled>
      <Logo color={primary} forIe />
      <Nav color={primary} onMentionsLegalesClick={onMentionsLegalesClick} />
    </HeaderStyled>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
